export const routes = [
    {
        path: '/employees',
        name: 'employees.browse',
        component: () => import(/* webpackChunkName: "EmployeesBrowse" */ '@/views/app/EmployeesBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/employees/:id/clone',
        name: 'employees.clone',
        component: () => import(/* webpackChunkName: "EmployeesActions" */ '@/views/app/EmployeesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/employees/create',
        name: 'employees.create',
        component: () => import(/* webpackChunkName: "EmployeesActions" */ '@/views/app/EmployeesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/employees/:id/edit',
        name: 'employees.edit',
        component: () => import(/* webpackChunkName: "EmployeesActions" */ '@/views/app/EmployeesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/employees/:id/delete',
        name: 'employees.delete',
        component: () => import(/* webpackChunkName: "EmployeesActions" */ '@/views/app/EmployeesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
    {
        path: '/employees/:id/show',
        name: 'employees.show',
        component: () => import(/* webpackChunkName: "EmployeesActions" */ '@/views/app/EmployeesActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
]